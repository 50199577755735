import {
  IMAGE_BROADCAST_AVAILABLE,
  OPERATIONAL_PARAMETERS_AVAILABLE,
  WELL_ANALYSE_AVAILABLE,
  WELL_DETAILS_AVAILABLE,
} from 'src/api/consts';
import { di } from 'src/packages/di';
import { addDirectoriesService } from 'src/packages/directories/addDirectoriesService';
import { addFavoritesWellsService } from 'src/packages/favorites-wells-service/addFavoritesService';
import { addFullscreeenService } from 'src/packages/fullscreen-service/addFullscreenService';
import { addLocalStorageService } from 'src/packages/local-storage-service/addLocalStorageService';
import { addOperationsService } from 'src/packages/operations-service/addOperationsService';
import { addSessionService } from 'src/packages/session-service/addSessionService';
import { addSoundAlarmService } from 'src/packages/sound-alarm/addSoundAlarmService';
import { addTabTemplatesService } from 'src/packages/tab-templates-service/addTabTemplatesService';
import { addWellLogsTemplateService } from 'src/packages/well-logs-template-service/addWellLogsTemplateService';
import { addWellStatusService } from 'src/packages/well-status-service/addWellStatusService';
import { addWidgetStoreService } from 'src/packages/widget-store-service/addWidgetStoreService';

import type { Auth } from '../auth/AuthStore';

import { addClassesOfUnitsService } from '../../packages/class-of-units-service/addClassesOfUntisService';
import { addLanguageService } from '../../packages/language/addLanguageService';
import { addApplicationBroadcastService } from '../application-broadcast-service/addApplicationBroadcastService';
import { addAuthService } from '../auth/addAuthService';
import { ForbiddenApiError, ValidationTextApiError } from '../errors';
import { addI18Service } from '../i18n/addI18Service';
import { addNotificationsStore } from '../notifications-store/addNotificationsStore';

import {
  addCommonServices,
  registerSettingScreenRenderer,
  registerWellLogsWidgetFeature,
  registerFilterWidgetFeature,
  registerWellListWidgetFeature,
  registerWellDetailsWidgetFeature,
  registerWellAnalyseWidgetFeature,
  registerImageBroadcastWidgetFeature,
  registerOperationalParametersFeature,
} from './commonServices';

type TInitAppServicesParams = {
  userInfo: Auth.UserInfo;
};

export async function initAppServices({ userInfo }: TInitAppServicesParams) {
  // core
  addNotificationsStore(di);
  await addI18Service(di);

  return Promise.all([
    addCommonServices(di),
    addLocalStorageService(di),
    addOperationsService(di),
    addApplicationBroadcastService(di),
    addWidgetStoreService(di),
    addAuthService(di, userInfo),
    addFavoritesWellsService(di),
    addTabTemplatesService(di),
    addFullscreeenService(di),
    addLanguageService(di),
    addWellStatusService(di),
    addSessionService(di),
    addWellLogsTemplateService(di),
    addDirectoriesService(di),
    addClassesOfUnitsService(di),
    addSoundAlarmService(di),

    registerSettingScreenRenderer(di),
    registerWellLogsWidgetFeature(di),
    registerFilterWidgetFeature(di),
    registerWellListWidgetFeature(di),
    WELL_DETAILS_AVAILABLE && registerWellDetailsWidgetFeature(di),
    WELL_ANALYSE_AVAILABLE && registerWellAnalyseWidgetFeature(di),
    IMAGE_BROADCAST_AVAILABLE && registerImageBroadcastWidgetFeature(di),
    OPERATIONAL_PARAMETERS_AVAILABLE && registerOperationalParametersFeature(di),
  ]).catch((e) => {
    if (e instanceof ValidationTextApiError) {
      if (e.axiosError?.response?.status === 403) {
        return 'Forbidden';
      }
    }
    if (e instanceof ForbiddenApiError) {
      return 'Forbidden';
    }
    console.error(e);
    return 'Unknown';
  });
}
