import { imageBroadcastApplyChanges } from 'src/packages/widget-apply-changes-service/applyers/imageBroadcastApplyChanges';
import { operationalParametersApplyChanges } from 'src/packages/widget-apply-changes-service/applyers/operationalParametersApplyChanges';
import { wellAnalyseApplyChanges } from 'src/packages/widget-apply-changes-service/applyers/wellAnalyseApplyChanges';
import { wellDetailsApplyChanges } from 'src/packages/widget-apply-changes-service/applyers/wellDetailsApplyChanges';
import { wellListApplyChanges } from 'src/packages/widget-apply-changes-service/applyers/wellListApplyChanges';
import { wellLogsApplyChanges } from 'src/packages/widget-apply-changes-service/applyers/wellLogsApplyChanges';
import { wellsFilterApplyChanges } from 'src/packages/widget-apply-changes-service/applyers/wellsFilterApplyChanges';
import { WidgetChangesApplyerService } from 'src/packages/widget-apply-changes-service/WidgetApplyChangesService';
import { OperationsLogPublisherService } from 'src/pages/dashboard/features/workspace/api/OperationsLogPublisherService';
import { StompPublisherService } from 'src/pages/dashboard/features/workspace/api/StompPublisherService';
import { PreloadWidgetService } from 'src/pages/dashboard/features/workspace/services/PreloadWidgetService';
import { renderImageBroadcastWidget } from 'src/pages/dashboard/features/workspace/services/renderImageBroadcastWidget';
import { renderOperationalParametersWidget } from 'src/pages/dashboard/features/workspace/services/renderOperationalParametersWidget';
import { renderWellAnalyseWidget } from 'src/pages/dashboard/features/workspace/services/renderWellAnalyseWidget';
import { renderWellDetailsWidget } from 'src/pages/dashboard/features/workspace/services/renderWellDetailsWidget';
import { renderWellListWidget } from 'src/pages/dashboard/features/workspace/services/renderWellListWidget';
import { renderWellLogsWidget } from 'src/pages/dashboard/features/workspace/services/renderWellLogsWidget';
import { renderWellsFilterWidget } from 'src/pages/dashboard/features/workspace/services/renderWellsFilterWidget';
import { WidgetRendererService } from 'src/pages/dashboard/features/workspace/services/WidgetRendererService';
import { renderDevSettingsScreen } from 'src/pages/settings/features/screen/services/renderDevSetttingsScreen';
import { renderObjectsListScreen } from 'src/pages/settings/features/screen/services/renderObjectsListScreen';
import { renderTabTemplatesDetailsScreen } from 'src/pages/settings/features/screen/services/renderTabTemplatesDetailsScreen';
import { renderTabTemplatesListScreen } from 'src/pages/settings/features/screen/services/renderTabTemplatesListScreen';
import { renderWellLogsTemplateDetailsScreen } from 'src/pages/settings/features/screen/services/renderWellLogsTemplateDetailsScreen';
import { renderWidgetTemplatesListScreen } from 'src/pages/settings/features/screen/services/renderWidgetTemplatesListScreen';
import { SettingsScreenRendererService } from 'src/pages/settings/features/screen/SettingsScreenRendererService';
import { mapImageBroadcastTemplate } from 'src/serializers/widget-templates/mappers/mapImageBroadcastTemplate';
import { mapOperationalParametersTemplate } from 'src/serializers/widget-templates/mappers/mapOperationalParametersTemplate';
import { mapWellAnalyseTemplate } from 'src/serializers/widget-templates/mappers/mapWellAnalyseTemplate';
import { mapWellDetailsTemplate } from 'src/serializers/widget-templates/mappers/mapWellDetailsTemplate';
import { mapWellListTemplate } from 'src/serializers/widget-templates/mappers/mapWellListTemplate';
import { mapWellLogsTemplate } from 'src/serializers/widget-templates/mappers/mapWellLogsTemplate';
import { mapWellsFilterTemplate } from 'src/serializers/widget-templates/mappers/mapWellsFilter';
import { serializeImageBroadcastTemplate } from 'src/serializers/widget-templates/serializers/serializeImageBroadcastTemplate';
import { serializeOperationalParametersTemplate } from 'src/serializers/widget-templates/serializers/serializeOperationalParametersTemplate';
import { serializeWellAnalyseTemplate } from 'src/serializers/widget-templates/serializers/serializeWellAnalyseTemplate';
import { serializeWellDetailsTemplate } from 'src/serializers/widget-templates/serializers/serializeWellDetailsTemplate';
import { serializeWellListTemplate } from 'src/serializers/widget-templates/serializers/serializeWellListTemplate';
import { serializeWellLogsTemplate } from 'src/serializers/widget-templates/serializers/serializeWellLogsTemplate';
import { serializeWellsFilterTemplate } from 'src/serializers/widget-templates/serializers/serializeWellsFilterTemplate';
import { WidgetTemplateSerializerService } from 'src/serializers/widget-templates/WidgetTemplateSerializerService';
import { mapImageBroadcastWidget } from 'src/serializers/widgets/mapImageBroadcastWidget';
import { mapOperationalParametersWidget } from 'src/serializers/widgets/mapOperationalParametersWidget';
import { mapWellAnalyseWidget } from 'src/serializers/widgets/mapWellAnalyseWidget';
import { mapWellDetailsWidget } from 'src/serializers/widgets/mapWellDetailsWidget';
import { mapWellListWidget } from 'src/serializers/widgets/mapWellListWidget';
import { mapWellLogsWidget } from 'src/serializers/widgets/mapWellLogsWidget';
import { mapWellsFilter } from 'src/serializers/widgets/mapWellsFilter';
import { serializeImageBroadcastWidget } from 'src/serializers/widgets/serializeImageBroadcastWidget';
import { serializeOperationalParametersWidget } from 'src/serializers/widgets/serializeOperationalParametersWidget';
import { serializeWellAnalyseWidget } from 'src/serializers/widgets/serializeWellAnalyseWidget';
import { serializeWellDetailsWidget } from 'src/serializers/widgets/serializeWellDetailsWidget';
import { serializeWellListWidget } from 'src/serializers/widgets/serializeWellListWidget';
import { serializeWellLogsWidget } from 'src/serializers/widgets/serializeWellLogsWidget';
import { serializeWellsFilter } from 'src/serializers/widgets/serializeWellsFilter';
import { WidgetSerializerService } from 'src/serializers/WidgetSerializerService';

import type { IOtherSettingsService } from '../../packages/others-settings/OthersSettingsStore';
import type { IThemeService } from '../theme/ThemeStore';
import type { IServicesCollection } from 'src/packages/di';
import type { IWidgetChangesApplyerService } from 'src/packages/widget-apply-changes-service/WidgetApplyChangesService';
import type { IOperationsStompPublisherService } from 'src/pages/dashboard/features/workspace/api/OperationsLogPublisherService';
import type { IStompPublisherService } from 'src/pages/dashboard/features/workspace/api/StompPublisherService';
import type { IWidgetTemplateSerializerService } from 'src/serializers/widget-templates/WidgetTemplateSerializerService';
import type { IWidgetSerializerService } from 'src/serializers/WidgetSerializerService';

import { OtherSettingsStore } from '../../packages/others-settings/OthersSettingsStore';
import { renderBasicParametersScreen } from '../../pages/settings/features/screen/services/renderBasicParametersScreen';
import { renderOthersScreen } from '../../pages/settings/features/screen/services/renderOthersScreen';
import { ThemeStore } from '../theme/ThemeStore';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    widgetSerializer: IWidgetSerializerService;
    widgetTemplateSerializer: IWidgetTemplateSerializerService;
    widgetRenderer: WidgetRendererService;
    widgetChangesApplyer: IWidgetChangesApplyerService;
    preloadService: PreloadWidgetService;
    settingsScreenRenderer: SettingsScreenRendererService;
    stompCachePublisherService: IStompPublisherService;
    stompOperationsPublisherService: IOperationsStompPublisherService;
    theme: IThemeService;
    otherSettings: IOtherSettingsService;
  }
}

export function addCommonServices(di: IServicesCollection) {
  const stompCachePublisherService = new StompPublisherService();
  const stompOperationsPublisherService = new OperationsLogPublisherService();

  stompCachePublisherService.init();

  di.addSingleton('preloadService', new PreloadWidgetService())
    .addSingleton('widgetSerializer', new WidgetSerializerService())
    .addSingleton('widgetTemplateSerializer', new WidgetTemplateSerializerService())
    .addSingleton('widgetRenderer', new WidgetRendererService())
    .addSingleton('widgetChangesApplyer', new WidgetChangesApplyerService())
    .addSingleton('settingsScreenRenderer', new SettingsScreenRendererService())
    .addSingleton('stompCachePublisherService', stompCachePublisherService)
    .addSingleton('stompOperationsPublisherService', stompOperationsPublisherService)
    .addSingleton('theme', new ThemeStore())
    .addSingleton('otherSettings', new OtherSettingsStore());
}

export function registerSettingScreenRenderer(di: IServicesCollection) {
  const settingsScreenRenderer = di.requireService('settingsScreenRenderer');

  settingsScreenRenderer
    .registerRenderer('widget-template-details', renderWellLogsTemplateDetailsScreen)
    .registerRenderer('object-list', renderObjectsListScreen)
    .registerRenderer('widget-templates-list', renderWidgetTemplatesListScreen)
    .registerRenderer('tab-templates-list', renderTabTemplatesListScreen)
    .registerRenderer('tab-template-details', renderTabTemplatesDetailsScreen)
    .registerRenderer('dev-settings', renderDevSettingsScreen)
    .registerRenderer('basic-parameters', renderBasicParametersScreen)
    .registerRenderer('others', renderOthersScreen);
}

export function registerWellLogsWidgetFeature(di: IServicesCollection) {
  const widgetSerializer = di.requireService('widgetSerializer');
  widgetSerializer
    .registerSerializer('well-logs-widget', serializeWellLogsWidget)
    .registerMapper('well-logs-widget', mapWellLogsWidget);

  const widgetTemplateSerializer = di.requireService('widgetTemplateSerializer');
  widgetTemplateSerializer
    .registerSerializer('well-logs-widget', serializeWellLogsTemplate)
    .registerMapper('well-logs-widget', mapWellLogsTemplate);

  const widgetRenderer = di.requireService('widgetRenderer');
  widgetRenderer.registerRenderer('well-logs-widget', renderWellLogsWidget);

  const widgetChangesApplyer = di.requireService('widgetChangesApplyer');
  widgetChangesApplyer.register('well-logs-widget', wellLogsApplyChanges);
}

export function registerFilterWidgetFeature(di: IServicesCollection) {
  const widgetSerializer = di.requireService('widgetSerializer');
  widgetSerializer
    .registerSerializer('filter-control', serializeWellsFilter)
    .registerMapper('filter-control', mapWellsFilter);

  const widgetTemplateSerializer = di.requireService('widgetTemplateSerializer');
  widgetTemplateSerializer
    .registerSerializer('filter-control', serializeWellsFilterTemplate)
    .registerMapper('filter-control', mapWellsFilterTemplate);

  const widgetRenderer = di.requireService('widgetRenderer');
  widgetRenderer.registerRenderer('filter-control', renderWellsFilterWidget);

  const widgetChangesApplyer = di.requireService('widgetChangesApplyer');
  widgetChangesApplyer.register('filter-control', wellsFilterApplyChanges);
}

export function registerWellListWidgetFeature(di: IServicesCollection) {
  const widgetSerializer = di.requireService('widgetSerializer');
  widgetSerializer
    .registerSerializer('well-list-control', serializeWellListWidget)
    .registerMapper('well-list-control', mapWellListWidget);

  const widgetTemplateSerializer = di.requireService('widgetTemplateSerializer');
  widgetTemplateSerializer
    .registerSerializer('well-list-control', serializeWellListTemplate)
    .registerMapper('well-list-control', mapWellListTemplate);

  const widgetRenderer = di.requireService('widgetRenderer');
  widgetRenderer.registerRenderer('well-list-control', renderWellListWidget);

  const widgetChangesApplyer = di.requireService('widgetChangesApplyer');
  widgetChangesApplyer.register('well-list-control', wellListApplyChanges);
}

export function registerWellDetailsWidgetFeature(di: IServicesCollection) {
  const widgetSerializer = di.requireService('widgetSerializer');
  widgetSerializer
    .registerSerializer('well-details-widget', serializeWellDetailsWidget)
    .registerMapper('well-details-widget', mapWellDetailsWidget);

  const widgetTemplateSerializer = di.requireService('widgetTemplateSerializer');
  widgetTemplateSerializer
    .registerSerializer('well-details-widget', serializeWellDetailsTemplate)
    .registerMapper('well-details-widget', mapWellDetailsTemplate);

  const widgetRenderer = di.requireService('widgetRenderer');
  widgetRenderer.registerRenderer('well-details-widget', renderWellDetailsWidget);

  const widgetChangesApplyer = di.requireService('widgetChangesApplyer');
  widgetChangesApplyer.register('well-details-widget', wellDetailsApplyChanges);
}

export function registerWellAnalyseWidgetFeature(di: IServicesCollection) {
  const widgetSerializer = di.requireService('widgetSerializer');
  widgetSerializer
    .registerSerializer('well-analyse-widget', serializeWellAnalyseWidget)
    .registerMapper('well-analyse-widget', mapWellAnalyseWidget);

  const widgetTemplateSerializer = di.requireService('widgetTemplateSerializer');
  widgetTemplateSerializer
    .registerSerializer('well-analyse-widget', serializeWellAnalyseTemplate)
    .registerMapper('well-analyse-widget', mapWellAnalyseTemplate);

  const widgetRenderer = di.requireService('widgetRenderer');
  widgetRenderer.registerRenderer('well-analyse-widget', renderWellAnalyseWidget);

  const widgetChangesApplyer = di.requireService('widgetChangesApplyer');
  widgetChangesApplyer.register('well-analyse-widget', wellAnalyseApplyChanges);
}

export function registerImageBroadcastWidgetFeature(di: IServicesCollection) {
  const widgetSerializer = di.requireService('widgetSerializer');
  widgetSerializer
    .registerSerializer('image-broadcast-widget', serializeImageBroadcastWidget)
    .registerMapper('image-broadcast-widget', mapImageBroadcastWidget);

  const widgetTemplateSerializer = di.requireService('widgetTemplateSerializer');
  widgetTemplateSerializer
    .registerSerializer('image-broadcast-widget', serializeImageBroadcastTemplate)
    .registerMapper('image-broadcast-widget', mapImageBroadcastTemplate);

  const widgetRenderer = di.requireService('widgetRenderer');
  widgetRenderer.registerRenderer('image-broadcast-widget', renderImageBroadcastWidget);

  const widgetChangesApplyer = di.requireService('widgetChangesApplyer');
  widgetChangesApplyer.register('image-broadcast-widget', imageBroadcastApplyChanges);
}

export function registerOperationalParametersFeature(di: IServicesCollection) {
  const widgetSerializer = di.requireService('widgetSerializer');
  widgetSerializer
    .registerSerializer('well-operational-params-widget', serializeOperationalParametersWidget)
    .registerMapper('well-operational-params-widget', mapOperationalParametersWidget);

  const widgetTemplateSerializer = di.requireService('widgetTemplateSerializer');
  widgetTemplateSerializer
    .registerSerializer('well-operational-params-widget', serializeOperationalParametersTemplate)
    .registerMapper('well-operational-params-widget', mapOperationalParametersTemplate);

  const widgetRenderer = di.requireService('widgetRenderer');
  widgetRenderer.registerRenderer('well-operational-params-widget', renderOperationalParametersWidget);

  const widgetChangesApplyer = di.requireService('widgetChangesApplyer');
  widgetChangesApplyer.register('well-operational-params-widget', operationalParametersApplyChanges);
}
